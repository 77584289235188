import './App.css';
import Header from './components/Header';
import Chat from './components/Chat';
import Input from './components/Input';
import { useEffect, useState } from 'react';
import { fetchMoreQuestions } from './api/api';

function App() {

  const [file, setFile] = useState(null)
  const [id, setID] = useState(null)
  const [messages, setMessages] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [load, setLoad] = useState(false)
  const [showModal, setShowModal] = useState(true)
  const [showErrorModal, setErrorModal] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("filename")) {
      setFile({ name: localStorage.getItem("filename") })
      setID(localStorage.getItem("id"))
    }
    if (localStorage.getItem("chats")) {
      setMessages(JSON.parse(localStorage.getItem("chats")))
    }
    getQues()
  }, [])

  const getQues = async () => {
    const moreQuestions = await fetchMoreQuestions(localStorage.getItem("id"))
    if (moreQuestions === null) {
      setSuggestions([])
    } else {
      setSuggestions(moreQuestions)
    }
  }

  const reset = () => {
    localStorage.clear()
    setMessages([])
    setFile(null)
    setErrorModal(true)
  }

  return (
    <div className="App">
      {showModal && <div id="myModal" class="modal">
        <div class="modal-content">
          <span onClick={() => setShowModal(false)} class="close">&times;</span>
          <h6 style={{ color: "rgb(3, 133, 3)", marginTop: "35px", fontStyle: "italic" }}>Now accepting multiple file upload and different document format</h6>
          <h2>Upload and Ask: Your Document Question Assistant</h2>
          <p>Easily upload multiple files in various formats, including PDF, DOC, DOCX, CSV, and TXT, and ask questions based on the content! Whether it's a report, academic paper, or any other document, our platform enables you to quickly extract insights and get answers to specific queries. Just upload your files, ask your question, and receive responses tailored to each document’s content—all in one seamless experience.</p>
          <div>
            <button style={{ fontSize: "16px" }} onClick={() => setShowModal(false)}>Close</button>
            <button><a style={{ color: "white", textDecoration: "none" }} href='mailto:uahomorejoice@gmail.com'>Contact Developer</a></button>
          </div>
        </div>
      </div>}
      {showErrorModal && <div id="myModal" class="modal">
        <div class="modal-content">
          <span onClick={() => window.location.reload()} class="close">&times;</span>
          <h2>Session Expired</h2>
          <p>Please Upload Your Documents Again</p>
          <div>
            <button style={{ width: "100%" }} onClick={() => window.location.reload()}>Close</button>
          </div>
        </div>
      </div>}
      <Header setFile={setFile} file={file} setShowModal={setShowModal} setID={setID} setMessages={setMessages} setSuggestions={setSuggestions} />
      <Chat messages={messages} load={load} file={file} suggestions={suggestions} setSuggestions={setSuggestions} id={id} setLoad={setLoad} setMessages={setMessages} reset={reset} />
      <Input file={file} setMessages={setMessages} messages={messages} setLoad={setLoad} id={id} reset={reset} />
    </div>
  );
}

export default App;
